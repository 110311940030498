<template>
    <div class="leftList">
        <div class="user-center__R8SKINS-logo">
          <div
            class="picture-container user-center__R8SKINS-logo__picture"
            style=" width: 100%;height: 60px;"
          >
            <div
              class="picture"
              style="
                background-size: contain;
              "
            ></div>
          </div>
        </div>
        <ul
          class="Menu ivu-menu ivu-menu-light ivu-menu-vertical"
          style="width: auto"
        >
          <li class="ivu-menu-item  ivu-menu-item-selected" :class="this.$store.state.isroute == 1 ? 'ivu-menu-item-active' : '' ">
            <router-link
              to="/userinfo"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe65a;</i>个人中心
            </router-link>
          </li>
          <li class="ivu-menu-item ivu-menu-item-selected" :class="this.$store.state.isroute == 2 ? 'ivu-menu-item-active' : '' ">
            <router-link
              to="/recaption"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe61d;</i>我的库存
            </router-link>
          </li>
          <li class="ivu-menu-item ivu-menu-item-selected" :class="this.$store.state.isroute == 3 ? 'ivu-menu-item-active' : '' ">
            <router-link
              to="/agency"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i class="iconfont" style="font-size: 19px">&#xe63e;</i>合作中心
            </router-link>
          </li>
          <li class="ivu-menu-item" :class="this.$store.state.isroute == 4 ? 'ivu-menu-item-active' : '' ">
            <router-link to="/record" class=""
              ><i class="iconfont" style="font-size: 19px">&#xe61e;</i>
              充值记录
            </router-link>
          </li>
        </ul>
    </div>
</template>

<script>
export default {
    data(){
        return {
            isrouter:1,//设置选择状态
        }
    },
}
</script>

<style scoped lang='less'>
@import url("../assets/csscopy/user/share.css");
@media screen and (max-width: 800px) {
.leftList{
  width: 20% !important;
}
}
.picture{
  background-image: url("../assets/img/cslogo.png") !important;
}
.ivu-menu-item a{
  text-align: center;
}
.user-center__R8SKINS-logo{
  padding: 0px;
}
</style>