<template>
  <div class="main">
    <div id="user" class="app-container">
      <!-- <div class="leftList">
        <div class="user-center__R8SKINS-logo">
          <div
            class="picture-container user-center__R8SKINS-logo__picture"
            style="padding-top: 13.0169%; width: 100%"
          >
            <div
              class="picture"
              style="
                background-image: url('http://r8skins.com/img/logo.6a88e67b.png');
                background-size: contain;
              "
            ></div>
          </div>
        </div>
        <ul
          class="Menu ivu-menu ivu-menu-light ivu-menu-vertical"
          style="width: auto"
        >
          <li class="ivu-menu-item  ivu-menu-item-selected">
            <a
              href="/#/userinfo"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe65a;</i
              >个人中心
            </a>
          </li>
          <li class="ivu-menu-item  ivu-menu-item-selected">
            <a
              href="/#/recaption"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61d;</i
              >我的库存
            </a>
          </li>
          <li class="ivu-menu-item  ivu-menu-item-selected">
            <a
              href="/#/agency"
              class="router-link-exact-active router-link-active"
              aria-current="page"
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61a;</i
              >合作中心
            </a>
          </li>
          <li class="ivu-menu-item ivu-menu-item-active">
            <a href="/#/record" class=""
              ><i
                class="iconfont"
                style="font-size: 19px"
              >&#xe61e;</i>
              充值记录
            </a>
          </li>
        </ul>
      </div> -->
      <leftList></leftList>


      <div class="rightView">
        <div class="recaptionBox">
          <div class="recaptionBox_bg" style="position: relative;">
            <div class="recaptionBox__title">
              <div class="recaptionBox__title__left">
                <div class="recaptionBox__title__left__stock">充值记录</div>
                <div class="recaptionBox__title__left__test"></div>
              </div>
            </div>
            <div class="record-content">
              <div class="ivu-table-wrapper">
                <div class="ivu-table ivu-table-default">
                  <!---->
                  <div class="ivu-table-header" style="background-color: rgb(40, 39, 54);">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      style="width: 1272px"
                      class="tables"
                    >
                      <colgroup>
                        <col width="317" />
                        <col width="318" />
                        <col width="318" />
                        <col width="318" />
                        <!---->
                      </colgroup>
                      <thead>
                        <tr>
                          <th class="ivu-table-column-CESI1X">
                            <div class="ivu-table-cell">
                              <span class="">订单号</span>
                              <!---->
                              <!---->
                            </div>
                            <!---->
                          </th>
                          <th class="ivu-table-column-A6TWrm">
                            <div class="ivu-table-cell">
                              <span class="">充值金额</span>
                              <!---->
                              <!---->
                            </div>
                            <!---->
                          </th>
                          <th class="ivu-table-column-hN2YQO">
                            <div class="ivu-table-cell">
                              <span class="">状态</span>
                              <!---->
                              <!---->
                            </div>
                            <!---->
                          </th>
                          <th class="ivu-table-column-iGgBmA">
                            <div class="ivu-table-cell">
                              <span class="">时间</span>
                              <!---->
                              <!---->
                            </div>
                            <!---->
                          </th>
                          <!---->
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div class="ivu-table-body" style="display: none">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      style="width: 1272px"
                    >
                      <colgroup>
                        <col width="317" />
                        <col width="318" />
                        <col width="318" />
                        <col width="318" />
                      </colgroup>
                      <tbody class="ivu-table-tbody"></tbody>
                    </table>
                  </div>
                  <!---->
                  <div class="ivu-table-header">
                    <table
                      cellspacing="0"
                      cellpadding="0"
                      border="0"
                      style="width: 1272px"
                      class="tables"
                    >
                      <colgroup>
                        <col width="317" />
                        <col width="318" />
                        <col width="318" />
                        <col width="318" />
                        <!---->
                      </colgroup>
                      <thead v-for="(item,index) in record.data" :key="index">
                        <tr>
                          <th class="ivu-table-column-CESI1X">
                            <div class="ivu-table-cell">
                              <span class="" v-html="$options.filters.testfile(item.order_id)"></span>
                            </div>
                          </th>
                          <th class="ivu-table-column-A6TWrm">
                            <div class="ivu-table-cell">
                              <span class="">{{item.coin}}</span>
                            </div>
                          </th>
                          <th class="ivu-table-column-hN2YQO">
                            <div class="ivu-table-cell">
                              <span class="">{{item.type == '0' ? '等待充值' : item.type == '1' ? '成功' : '成功'}}</span>
                            </div>
                          </th>
                          <th class="ivu-table-column-iGgBmA">
                            <div class="ivu-table-cell">
                              <span class="">{{item.create_time}}</span>
                            </div>
                          </th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <!-- <div class="ivu-table-tip">
                    <table cellspacing="0" cellpadding="0" border="0">
                      <tbody>
                        <tr style="text-align: center;background-color: #282736;">
                          <td style="width: 1272px"><span>10076</span></td>
                          <td style="width: 1272px"><span>100.00</span></td>
                          <td style="width: 1272px"><span>成功</span></td>
                          <td style="width: 1272px"><span>5.27-19:13</span></td>
                        </tr>
                        <tr style="text-align: center;background-color: #282736;">
                          <td style="width: 1272px"><span>10076</span></td>
                          <td style="width: 1272px"><span>100.00</span></td>
                          <td style="width: 1272px"><span>成功</span></td>
                          <td style="width: 1272px"><span>5.27-19:13</span></td>
                        </tr>
                        <tr style="text-align: center;background-color: #282736;">
                          <td style="width: 1272px"><span>10076</span></td>
                          <td style="width: 1272px"><span>100.00</span></td>
                          <td style="width: 1272px"><span>成功</span></td>
                          <td style="width: 1272px"><span>5.27-19:13</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div> -->
                  <!---->
                  <!---->
                  <!---->
                  <!---->
                </div>
                <div class="ivu-table-resize-line" style="display: none"></div>
                <!---->
                <!----><object
                  tabindex="-1"
                  type="text/html"
                  data="about:blank"
                  style="
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    border: none;
                    padding: 0px;
                    margin: 0px;
                    opacity: 0;
                    z-index: -1000;
                    pointer-events: none;
                  "
                ></object>
              </div>
              <!---->
            </div>

            <div class="pageBox">
              <ul class="ivu-page">
                <li title="1" class="ivu-page-item ivu-page-item-active">
                  <el-pagination
                    small
                    background
                    layout="prev, pager, next"
                    @current-change="pagedata"
                    :total=total
                    :page-size="limit"
                    >
                  </el-pagination>
                </li>
              </ul>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 红包 -->
    <Redenvelopes></Redenvelopes>
  </div>
</template>

<script>
import Redenvelopes from "../components/Redenvelopes";
import leftList from "../components/userleftList"

export default {
  components: {
    Redenvelopes,
    leftList,
  },
  data() {
    return {
      record:'',//充值记录
      total:0,//多少页
      page:1,//分页
      limit:15,//一页多少条
    };
  },
  methods: {
    // 红包
    redPrize(){
      document.getElementsByClassName('ivu-modal')[0].style.display="block";
      document.getElementById('isquan').classList.add("backghui")
    },
    //拿到当前页数
    pagedata(key){
      this.page = key
      this.getre()
    },
    //获取充值记录
    getre(){
      this.$axios({
        url:'pay/get/list',
        method:'post',
        data:{
          page:this.page,
          limit:this.limit
        }
      }).then( res => {
        if(res.data.status == 200){
          this.record = res.data.data
          this.total = res.data.data.count
        }
      })
    },
    //登录
    handleZhu() {
      document.getElementsByClassName("form-wnd")[0].classList.add("active");
      document.getElementsByClassName("popup-bg")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[0].classList.add("active");
      document.getElementsByClassName("switch-block")[1].classList.remove("active");
      document.getElementsByClassName("button")[0].classList.add("active");
      document.getElementsByClassName("button")[1].classList.remove("active");
      this.isBar = false;
      this.password=''
    },
  },
  mounted() {
    if(!localStorage.getItem("token")){
      this.$message({
        showClose: true,
        message: '请先登录'
      });
      this.$router.push('/')
      //提示登录框
      setTimeout( ()=> {
        this.handleZhu()
      },300)
    }
    this.getre()
  },
  //过滤器
  filters: {
    testfile (value) {
      const start = value.slice(12)
      // const end = value.slice(-5)
      return `${start}`
    }
  },
};
</script>

<style scoped lang='less'>
@import url("../assets/csscopy/user/share.css");
@import url("../assets/csscopy/user/record.css");
.picture{
  background-image: url("../assets/img/logo.6a88e67b.png") !important;
}
@media screen and (max-width: 800px) {
  .leftList{
    width: 20% !important;
  }
  .rightView{
    display:block !important;
    width: 80%;
  }
  .recaptionBox_bg{
    padding: 20px 10px;
    position: relative;
  }
  .tables{
    width: 100% !important;
  }
  .pageBox{
    bottom: 2% !important;
    right: 0% !important;
    left: 1%;
  }
}
.pageBox{
  position: absolute;
  bottom: 2%;
  right: 2%;
}
</style>